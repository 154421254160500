import PlanningImage from "../images/planing.svg";
import ModelingImage from "../images/modeling.svg";
import DatabaseImage from "../images/database.svg";
import MobileImage from "../images/mobileapp.svg";
import ReferenceBookImage from "../images/referencebook.svg";

export const planningItems = [
    'Работа с КТ, МРТ и рентгенограммами в формате DICOM',
    'Рентгеноморфометрические измерения',
    'Позиционирование шаблонов имплантатов, применяемых на территории России',
    'Виртуальные операции с медицинскими изображениями',
    'Воссоздание «нормальной» анатомии ПТК',
    'Вычисление значений геометрических параметров ПТК',
    'Автоматический расчет теоретических значений параметров сагиттального баланса',
    'Автоматический и ручной режимы калибровки изображений',
    'Пошаговые подсказки при измерении геометрических параметров',
    'Создание отчета о планировании',
    'Совместимость с PACS-серверами',
    'Мобильное приложение «СпиноМетр» для расчета параметров сагиттального баланса'
];

export const modelingItems = [
    'Виртуальное биомеханическое моделирование вариантов лечения заболеваний и повреждений позвоночно-тазового комплекса',
    'Цифровое обоснование успешности хирургического лечения',
    'Автоматизированное распознавание КТ и построение моделей элементов ПТК',
    'Виртуальная установка имплантатов, применяемых на территории России',
    'Расчет механических свойств костной ткани на основе данных КТ',
    'Количественная оценка стабильности фиксации и «выживаемости» имплантатов',
    'Использование критериев оценки успешности лечения',
    'Возможность разработки и доклинической апробации новых имплантатов'
];

export const databaseItems = [
    "Анамнезы",
    "Медицинские изображения элементов позвоночно-тазового комплекса до операции, после операции, а также результаты планирования операции",
    "Данные о состоянии пациента до операции, послеоперац ии и в период реабилитации",
    "Модели элементов позвоночно-тазового комплекса",
    "Модели и шаблоны имплантатов, применяемых на территории России",
    "Модели установленных систем фиксации",
    "Механические свойства костных тканей, мягких тканей и имплантатов"
];


export let regimes = [
    {
        header: "Персональная виртуальная операционная",
        info: "В данном режиме врач планирует лечение «стандартных» клинических случаев и использует настольный " +
            "компьютер в клинике, что позволяет решать задачу предоперационного планирования в упрощенной постановке."
    },
    {
        header: "Региональный центр",
        info: "Режим предназначен для планирования клинических случаев, не укладывающихся в стандартные алгоритмы " +
            "лечения, в том числе для планирования сочетанных патологий. Подразумевается использование мощных " +
            "вычислительных компьютеров для решения задачи биомеханики в наиболее полной постановке."
    },
    {
        header: "Федеральный центр",
        info: "Режим представляет собой совокупность административных и программно-инженерных конструкторских " +
            "ресурсов, обеспечивающих функционирование и развитие Системы, и не предполагает решение конкретных " +
            "медицинских задач, связанных с планированием операций. Федеральный центр управляет сетью Региональных " +
            "центров поддержки принятия врачебных решений, реализующих режим Региональный центр. " +
            "В данном режиме все научные задачи, а также технические задачи, связанные с разработкой, модернизацией, обучением " +
            "и поддержкой пользователей Системы, выполняются в Технологическом центре поддержки принятия врачебных решений."
    },
    {
        header: "Технологический центр",
        info: "Технологический центр функционирует на базе организации-разработчика Системы – Саратовского " +
            "университета и является партнером Федерального центра. Технологический центр имеет в своем " +
            "составе команду программистов, научных работников и может осуществлять поддержку принятия врачебных " +
            "решений для наиболее сложных и тяжелых случаев заболеваний и повреждений позвоночно-тазового комплекса. " +
            "При этом для решения задач предоперационного планирования может использоваться как программное " +
            "обеспечение Системы, так и стороннее специализированное программное обеспечение."
    }
];

export let flowContent = [
    {
        type: "planning",
        title: "Планирование",
        image: PlanningImage
    },
    {
        type: "modeling",
        title: "Моделирование",
        image: ModelingImage
    },
    {
        type: "database",
        title: "Базы данных",
        image: DatabaseImage
    },
    {
        type: "directories",
        title: "Справочники",
        image: ReferenceBookImage
    },
    {
        type: "mobile",
        title: "Мобильные приложения",
        image: MobileImage
    },
];

export let spinometrParameters = [
    "тазовые параметры",
    "параметры позвоночника",
    "интегративный индекс расчета баланса"
];

export let mobileScaleParameters = [
    "Применяется для индивидуального заполнения пациентом анкеты Освестри",
    "Программа позволяет оправить результат опроса кому-либо посредством доступных на мобильном устройстве мессенджеров или направить их в специализированную базу данных.",
    "При взаимодействии со специализированной базой данных для идентификации пациента и опроса приложение использует QR-код"
];

export let lenkeParameters = [
    "Классификатор сколиотических деформаций АО Lenke",
    "Точные 3D модели возможных деформаций в соответствии с классификацией Lenke",
    "Трехмерный атлас сколиотических деформаций АО Lenke с описаниями"
];

export let aoParameters = [
    "Классификатор переломов позвоночника",
    "Точные 3D модели каждого возможного перелома позвоночника",
    "Трехмерный атлас всех возможных переломов позвоночника с описаниями"
];


