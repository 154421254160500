import React from 'react';
import './styles/App.css';
import {Sidebar} from "./components/Sidebar";
import {Content} from "./components/Content";
import {Header} from "./components/Header";
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
    return (
        <div className="App">
            <Header/>
            <div className="app-content">
                <Content/>
            </div>
            <Sidebar/>
        </div>
    );
}

export default App;
