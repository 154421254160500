import React, {useCallback, useEffect, useState} from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowCircleUp} from "@fortawesome/free-solid-svg-icons";

export const ArrowTop = () => {
    let [isHidden, setHidden] = useState(true);

    let onScroll = useCallback(() => {
        const OFFSET = 200;
        setHidden(window.pageYOffset < OFFSET);
    }, [setHidden]);

    let goToTop = () => {
        window.scrollTo(window.pageYOffset, 0);
        window.location.hash = "#main";
    };

    useEffect(() => {
        window.addEventListener('scroll', onScroll);

        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    });

    return (
        <div onClick={goToTop} id="arrow-top" style={isHidden ? {display: 'none'} : {}}>
            <FontAwesomeIcon size='3x'
                             color="#7CB248"
                             icon={faArrowCircleUp}/>
        </div>
    )
};