import axios from "axios";
import swal from "sweetalert2";

const API_URL = "";
const API_SUFFIX = "/software/api/v1/";

const settings = {
    headers: {
        'Content-Type': 'application/json'
    }
};

const getMessage = (info) => `<span style="color:white">${info}<span>`;


const toast = swal.mixin({
    toast: true,
    position: 'top',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', swal.stopTimer);
        toast.addEventListener('mouseleave', swal.resumeTimer)
    }
});

function handleErrors(response) {
    let resp = response.response;
    let alertSettings = {
        icon: 'error',
        background: '#dc3545'
    };
    if (!resp) {
        alertSettings.title = getMessage('Ошибка сети!');
        toast.fire(alertSettings)
    } else if (resp.status === 400) {
        alertSettings.title = getMessage('Ошибка клиента!');
        toast.fire(alertSettings)
    } else if (resp.status >= 500) {
        alertSettings.title = getMessage('Ошибка сервера!');
        swal.fire(alertSettings)
    }
}

async function postData(serviceName, data, successCallback, errorCallback) {
    return await axios.post(`${API_URL}${API_SUFFIX}${serviceName}`, JSON.stringify(data), settings)
        .then((response) => {
            if (successCallback) {
                successCallback()
            }

            let alertSettings = {
                icon: 'success',
                background: '#198754'
            };
            alertSettings.title = getMessage('Благодарим за регистрацию!');
            toast.fire(alertSettings)

        }, (error) => {
            handleErrors(error);
            if (errorCallback) {
                errorCallback()
            }
        });
}


function getData(serviceName) {
    return axios.get(`${API_URL}${API_SUFFIX}${serviceName}`, settings).catch(handleErrors)
}

async function getLatestSoftVersion() {
    let downloaderLatest = await getData("downloaders/latestVersion");
    if (downloaderLatest) {
        const link = document.createElement('a');
        link.href = `${API_URL}${API_SUFFIX}/downloaders/${downloaderLatest.data.id}/file`;
        link.setAttribute('download', `downloader.zip`);
        document.body.appendChild(link);
        link.click();
        link.remove();
    }
}

export const validateLoginIsUnique = async (login) => {
    let response = await getData(`users/login/${login}/validate`);
    return response ? response.data.isValid : true;
};

export const validateEmailIsUnique = async (email) => {
    let response = await getData(`users/email/${email}/validate`);
    return response ? response.data.isValid : true;
};

export const registerUser = (data, successCallback, errorCallback) => postData("users", data, successCallback, errorCallback);
export const getDownloader = () => getLatestSoftVersion();
