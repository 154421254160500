import React from 'react'
import "../styles/Contacts.css"
import FpiLogo from "../images/fpi.png";
import SsuLogo from "../images/ssu.png";
import SsmuLogo from "../images/ssmu.png";
import {ContactForm} from "./ContactForm";


export const Contacts = () => {
    return (
        <div id="contacts" className="pages pages-center pages-links">
            <div id="contacts-content" className="page d-flex align-items-center row">
                <div id="contacts-elements"  className="col-lg-6 col-md-6  col-sm-12 order-md-1 order-12">
                    <h1 className="page-title">СВЯЖИТЕСЬ С НАМИ</h1>
                    <div className="address">410012, Россия, Саратов, Астраханская, д. 83.</div>
                    <div className="email">smartplan@sgu.ru</div>
                    <div className="phone">+7 (8452) 51-15-27</div>
                    <div className="our-links">
                         <a className="url-link" target="_blank" rel="noopener noreferrer" href="https://fpi.gov.ru/">
                            <img id="fpi-logo" className="logo" src={FpiLogo} alt="ФПИ"/>
                        </a>
                        <a className="url-link" target="_blank" rel="noopener noreferrer" href="https://www.sgu.ru/">
                            <img id="sgu-logo" className="logo" src={SsuLogo} alt="СГУ"/>
                        </a>
                        <a className="url-link" target="_blank" rel="noopener noreferrer" href="https://www.sarniito.com/">
                            <img id="sgmu-logo" className="logo" src={SsmuLogo} alt="СГМУ"/>
                        </a>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-sm-6 order-md-6 order-1"><ContactForm/></div>
            </div>
        </div>
    )
};