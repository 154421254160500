import React from 'react'

export const ModuleInfoList = (props) => {
    const items = props.items;
    return (
        <div>
            <ul className="module-info-list">
                {
                    items.map((item, index) => (
                        <li className="module-info-item" key={index}>{item}</li>
                    ))
                }
            </ul>
        </div>
    )
};