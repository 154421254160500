import React from 'react'
import MobileImage from "../images/big_mobileapp.svg";
import {PageInfo} from "./PageInfo";
import {mobileScaleParameters, spinometrParameters} from "./database";
import "../styles/Mobile.css"


export const Mobile = () => {
    return (
        <div id="Mobile" className="pages app-info pages-links">
            <div className="app-page page row">
                <div id="mobile-image-box" className="page-image image app-part col-lg-6 col-md-6 col-sm-12">
                    <img id="mobile-image" className="mobile-image image" src={MobileImage} alt="Базы данных"/>
                </div>
                <div id="mobile-info"
                     className="page-info d-flex align-items-center app-part col-lg-6 col-md-6 col-sm-12">
                    <div>
                        <h3 className="page-title">МОБИЛЬНЫЕ ПРИЛОЖЕНИЯ:</h3>
                        <PageInfo pageType={"spinometr"}
                                  items={spinometrParameters}
                                  header={"«Спинометр» позволяет измерять"}/>
                        <PageInfo pageType={"scale"}
                                  items={mobileScaleParameters}
                                  header={"Опросник Освестри со шкалой «ВАШ»"}/>
                    </div>
                </div>
            </div>
        </div>
    )
};