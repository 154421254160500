import React from 'react'
import "../styles/Description.css"
import {Chapter} from "./Chapter";
import {regimes} from "./database";

export const SystemRegimes = () => {
    return (
        <div id="system-regimes" className="pages-center pages-links app-text">
            <div>
                <h1 id="system-regimes-title" className="page-title">РЕЖИМЫ РАБОТЫ СИСТЕМЫ</h1>
                <div id="system-regimes-body" className="description-text">
                    {
                        regimes.map((item, index) => (
                            <Chapter key={index} header={item.header} paragraph={item.info} />
                        ))
                    }
                </div>
            </div>
        </div>
    )
};