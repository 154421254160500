import React from "react";

export const ImageLinkBox = (props) => {
    let {type, title, image} = props;
    return (
        <div id={`flow-box-${type}`} className="flow-box">
            <a href={`#${type}`} title={title}>
                <img id={`${type}-image`} className={`${type} image`} src={image} alt={title}/>
            </a>
        </div>)
};