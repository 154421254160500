import React, {useRef} from "react";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";


export const SidebarListItem = (props) => {
    let isChosen = props.isChosen ? "chosen-item" : "";
    let classNames = `item ${isChosen}`;
     const ref = useRef(null);

    return (<li className="link">
        <OverlayTrigger
            key={'left'}
            placement={'left'}
            target={ref.current}
            overlay={
                <Tooltip container={ref.current}>
                    {props.pageTitle.replace(":", "") ?? ""}
                </Tooltip>
            }
        >
            <a href={props.href} ref={ref}><div className={classNames}/></a>
        </OverlayTrigger>
    </li>);
};