import {ModuleInfoList} from "./ModuleInfoList";
import React from "react";

export const PageInfo = (props) => {
    let {items, pageType, header, isAdaptive} = props;
    const adaptiveClasses = "col-lg-6 col-md-6 col-sm-12";
    return (
        <div id={`${pageType}-info`}
             className={`page-info d-flex align-items-center app-part ${isAdaptive ? adaptiveClasses : ""}`}>
            <div className="inner-page-info app-text">
                <h3  className="page-title">{header}</h3>
                <ModuleInfoList items={items}/>
            </div>
        </div>
    )
};