import React from 'react'
import ReferenceBookImage from "../images/big_referencebook.svg";
import {PageInfo} from "./PageInfo";
import {lenkeParameters, aoParameters} from "./database";


export const Directories = () => {
    return (
        <div id="directories" className="pages pages-links app-info">
            <div className="app-page page row">
                <div id="directories-image"
                     className="page-image image app-part col-lg-6 col-md-6 col-sm-12 order-md-6 order-sm-1">
                    <img id="directories-image" className="directories-image image" src={ReferenceBookImage}
                         alt="Справочники"/>
                </div>
                    <div id="directories-info"
                     className="page-info d-flex align-items-center app-part col-lg-6 col-md-6 order-md-1 order-sm-12">
                    <div>
                        <h3 className="page-title">СПРАВОЧНИКИ:</h3>
                        <span className="app-text">
                            <PageInfo pageType={"ao"}
                                      items={aoParameters}
                                      header={"3D Справочник-переходник AO/ASIF – AO/Spine"}/>
                             <PageInfo pageType={"lenke"}
                                       items={lenkeParameters}
                                       header={"3D Справочник сколиотических деформаций позвоночника АО Lenke"}/>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
};