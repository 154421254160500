import React from "react";
import {Description} from "./Description";
import {Flow} from "./Flow";
import {Contacts} from "./Contacts";
import {ArrowTop} from "./ArrowTop";
import {Directories} from "./Directories";
import {SystemRegimes} from "./SystemRegimes";
import {Mobile} from "./Mobile";
import {ModulePage} from "./ModulePage";
import {planningItems, modelingItems, databaseItems} from "./database";
import PlanningImage from "../images/big_planing.svg";
import ModelingImage from "../images/big_modeling.svg";
import DatabaseImage from "../images/big_database.svg";


export const Content = () => {
    return (
        <div id="Content" className="Content">
            <Description/>
            <Flow/>
            <SystemRegimes/>
            <ModulePage items={planningItems}
                        pageType={"planning"}
                        header={"ПРЕДОПЕРАЦИОННОЕ ГЕОМЕТРИЧЕСКОЕ\n ПЛАНИРОВАНИЕ:"}
                        image={PlanningImage}
                        imageAlt={"Планирование"}/>
            <ModulePage items={modelingItems}
                        pageType={"modeling"}
                        header={"ПРЕДОПЕРАЦИОННОЕ БИОМЕХАНИЧЕСКОЕ МОДЕЛИРОВАНИЕ:"}
                        image={ModelingImage}
                        imageAlt={"Моделирование"}
                        reverse={true}/>
            <ModulePage items={databaseItems}
                        pageType={"database"}
                        header={"БАЗА ДАННЫХ СИСТЕМЫ СОДЕРЖИТ:"}
                        image={DatabaseImage}
                        imageAlt={"Базы данных"}/>
            <Directories/>
            <Mobile/>
            <Contacts/>
            <ArrowTop/>
        </div>
    )
};