import React from 'react'
import "../styles/Flow.css"
import {ImageLinkBox} from "./ImageLinkBox";
import {flowContent} from "./database";


export const Flow = () => {
    return (
        <div id="Flow">
            <div id="flow-wrapper">
                <div id="flow-content">
                    {
                        flowContent.map((item, index) => (
                            <ImageLinkBox key={index} type={item.type} title={item.title} image={item.image}/>
                        ))
                    }
                </div>
            </div>
        </div>
    )
};