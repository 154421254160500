import React, {useCallback, useEffect, useState} from "react";
import {PageInfo} from "./PageInfo";
import {PageImage} from "./PageImage";

export const ModulePage = (props) => {
    let {items, pageType, header, image, imageAlt, reverse} = props;
    const [width, setWidth] = useState(window.innerWidth);
    const MIN_WIDTH = 768;

    const handleWindowResize = useCallback(event => {
        setWidth(window.innerWidth);
    }, []);

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, [handleWindowResize]);

    return (
        <div id={pageType} className="pages pages-links app-info">
            <div className="app-page page row">
                {reverse && width > MIN_WIDTH
                    ?
                    <>
                        <PageInfo pageType={pageType} items={items} header={header} isAdaptive={true}/>
                        <PageImage pageType={pageType} image={image} imageAlt={imageAlt}/>
                    </>
                    :
                    <>
                        <PageImage pageType={pageType} image={image} imageAlt={imageAlt}/>
                        <PageInfo pageType={pageType} items={items} header={header} isAdaptive={true}/>
                    </>
                }
            </div>
        </div>
    )
};