import React, {useEffect, useState} from 'react'
import "../styles/Main.css"
import {getDownloader} from "../api";
import Background from '../images/background.svg';
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

let sectionStyle = {
    backgroundImage: `url(${Background})`,
    height: '100vh',
    width: "100%",
    maxHeight: '100%',
    margin: 0,
    padding: 0,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};


export const Main = () => {
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        if (isLoading) {
            getDownloader().then(() => {
                setLoading(false);
            });
        }
    }, [isLoading]);

    const handleClick = () => setLoading(true);

    const spinner = <><Spinner as="span" animation="grow" role="status" aria-hidden="true"/>Загрузка...</>;

    return (
        <div id="main" className="main  pages-links" style={sectionStyle}>
            <div id="main-button-box" className="white-text text-center mb-5 wow fadeInLeft">
                <span className="page-title" style={{display: "none"}}>СКАЧАТЬ</span>
                <Button id="download-button" onClick={handleClick} variant="info" disabled={isLoading}
                        size="lg">
                    {isLoading ? spinner : 'Скачать'}
                </Button>
            </div>
        </div>
    )
};