import React from "react";
import {useForm} from "react-hook-form";
import {registerUser, validateEmailIsUnique, validateLoginIsUnique} from "../api";
import {validateMobile, validatePassword} from "./utils/validators";


export const ContactForm = () => {
    const {register, handleSubmit, errors, reset} = useForm();

    let requiredText = "Данное поле необходимо заполнить!";

    let onSubmit = async (data) => registerUser(data, reset);

    return (
        <form id="ContactsForm" className="ContactsForm form"
              onSubmit={handleSubmit(onSubmit)}>
            <input type="text"
                   id="contact-form-login"
                   name="login"
                   placeholder="Логин*"
                   className={`${errors.login && "error"} custom`}
                   ref={register({required: true, validate: validateLoginIsUnique})}/>
            <span id="contact-form-login-error" className={"error"}>
                {errors.login && errors.login.type === "required" && requiredText}
                {errors.login && errors.login.type === "validate" && "Такой логин уже существует!"}
            </span>
            <input type="password"
                   id="contact-form-password"
                   name="password"
                   placeholder="Пароль*"
                   className={`${errors.password && "error"} custom`}
                   ref={register({required: true, minLength: 8, validate: validatePassword})}/>
            <span id="contact-form-password-error" className={"error"}>
                {errors.password && errors.password.type === "required" && requiredText}
                {errors.password && errors.password.type === "minLength" && 'Минимальная длинна должна быть 8 символов!'}
                {errors.password && errors.password.type === "validate" && 'Пароль должен содержать минимум 1 латинский символ в нижнем регистре, ' +
                '1 латинский символ в верхнем регистре и цифру!'}
            </span>
            <input type="text"
                   id="contact-form-lastName"
                   name="lastName"
                   placeholder="Фамилия*"
                   className={`${errors.lastName && "error"} custom`}
                   ref={register({required: true})}/>
            <span id="contact-form-lastName-error" className={"error"}>
                {errors.lastName && requiredText}
            </span>
            <input type="text"
                   id="contact-form-name"
                   name="name"
                   placeholder="Имя*"
                   className={`${errors.name && "error"} custom`}
                   ref={register({required: true})}/>
            <span id="contact-form-name-error" className={"error"}>
                {errors.name && requiredText}
            </span>
            <input type="text"
                   id="contact-form-patronymic"
                   name="patronymic"
                   placeholder="Отчество"
                   className="custom"
                   ref={register}/>
            <input type="text"
                   id="contact-form-organization"
                   name="organization"
                   placeholder="Организация"
                   className="custom"
                   ref={register}/>
            <input type="text"
                   id="contact-form-email"
                   name="email"
                   placeholder="Эл. почта*"
                   className={`${errors.email && "error"} custom`}
                   ref={register({required: true, pattern: /^\S+@\S+$/i, validate: validateEmailIsUnique})}/>
            <span id="contact-form-email-error" className={"error"}>
                {errors.email && errors.email.type === 'required' && requiredText}
                {errors.email && errors.email.type === 'pattern' && "Неправильные формат электронной почты!"}
                {errors.email && errors.email.type === "validate" && "Такая электронная почта уже существует!"}
            </span>
            <input type="text"
                   id="contact-form-phone"
                   name="phone"
                   placeholder="Телефон"
                   className="custom"
                   ref={register({validate: validateMobile})}/>
            <span id="contact-form-phone-error" className={"error"}>
                {errors.phone && errors.phone.type === 'validate' && "Введите номер в формате: +7XXXXXXXXXX, 8XXXXXXXXXX, XXXXXX!"}
            </span>
            <input type="text"
                   id="contact-form-city"
                   name="city"
                   placeholder="Адрес"
                   className="custom"
                   ref={register}/>
            <div id="contact-form-button">
                <button className="form-button">Отправить</button>
            </div>
            <div id="confidential">
                <span> Нажимая на кнопку «Отправить», я даю согласие на обработку персональных данных и соглашаюсь c
                    условиями
                    &ensp;<a className="url-link" href="/Privacy_policy.pdf" target="_blank">Политики конфиденциальности</a>&ensp;
                    и&ensp;<a className="url-link" href="/Terms_of_use.pdf" target="_blank">Пользовательского соглашения</a>&ensp;
                </span>
            </div>
        </form>
    )
};