import React from "react";
import "../styles/Header.css"
import ProjectLogo from "../images/logo.png";
import {Main} from "./Main";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";


export const Header = () => {

    const links = {
        "#ContactsForm": "Зарегистрироваться",
        "#description": "О\u00A0нас",
        "#contacts": "Контакты"
    };

    return (
        <header>
            <Navbar collapseOnSelect className="header-navbar" expand="lg" bg="dark" variant="dark">
                <Navbar.Brand>
                    <div id="project-logo"><img id="logo" src={ProjectLogo} alt=""/></div>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto">
                        <Nav.Link className="header-link" href="mailto:smartplan@sgu.ru">smartplan@sgu.ru</Nav.Link>
                        {
                            Object.keys(links).map((key, index) => (
                                <Nav.Link className="header-link" key={index.toString()}
                                          href={key}>{links[key]}</Nav.Link>
                            ))
                        }
                        <NavDropdown title="Документация" id="basic-nav-dropdown" activeKey="">
                            <NavDropdown.Item target="_blank" href="/Desktop.pdf">SmartPlan Ortho 2D</NavDropdown.Item>
                            <NavDropdown.Divider/>
                            <NavDropdown.Item target="_blank" href="/Aoasif-Aospine.pdf">Переходник AO/ASIF –
                                AO/Spine</NavDropdown.Item>
                            <NavDropdown.Divider/>
                            <NavDropdown.Item target="_blank" href="/Lenke.pdf">Справочник сколиотических деформаций
                                позвоночника АО Lenke</NavDropdown.Item>
                            <NavDropdown.Divider/>
                            <NavDropdown.Item target="_blank" href="/Spinometr.pdf">Спинометр</NavDropdown.Item>
                            <NavDropdown.Divider/>
                            <NavDropdown.Item target="_blank" href="/Инструкция к запуску Системы.pdf">Инструкция по установке и запуску Системы
                            </NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown title="Стоимость" id="basic-nav-dropdown" activeKey="">
                            <NavDropdown.Item target="_blank"
                                              href="/Порядок определения размера лицензионных платежей Фонда.pdf">Порядок
                                определения размера лицензионных платежей Фонда</NavDropdown.Item>
                            <NavDropdown.Divider/>
                            <NavDropdown.Item target="_blank"
                                              href="/Постановление Правительства от 07 марта 2019 № 240.pdf">
                              Постановление Правительства от 07 марта 2019 № 240</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <Main/>
        </header>
    )
};