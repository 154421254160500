import React, {useCallback, useEffect, useState} from 'react'
import "../styles/Sidebar.css"
import {SidebarListItem} from "./SidebarListItem";


let getLink = (chosenPage) => {
    return (element, index) => {
        let page = element.getElementsByClassName("page-title")[0];
        let pageTitle = page ? page.textContent : "";
        return <SidebarListItem key={index.toString()}
                                href={`#${element.id}`}
                                isChosen={chosenPage === element.id}
                                pageTitle={pageTitle}/>
    };
};


export const Sidebar = () => {
        const [elements, setElements] = useState([]);
        let currentHash = window.location.hash.replace("#", "");
        const [chosenElement, setChosenElement] = useState(currentHash ? currentHash : "main");

        useEffect(() => {
            const elements = document.getElementsByClassName("pages-links");
            setElements([...elements]);
        }, [setElements]);

        let checkElementIsVisible = useCallback((element) => {
            let rect = element.getBoundingClientRect();
            return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
        }, []);

        let onPageScroll = useCallback(() => {
            let elements = document.getElementsByClassName("pages-links");
            let filteredElements = [].filter.call(elements, checkElementIsVisible);
            if (filteredElements.length > 0) {
                let chosenPageID = filteredElements[0].id;
                let hash = `#${chosenPageID}`;
                if (window.location.hash !== hash) {
                    window.history.pushState(null, null, `#${chosenPageID}`);
                    setChosenElement(chosenPageID)
                }

            }
        }, [setChosenElement, checkElementIsVisible]);

        useEffect(() => {
            window.addEventListener('scroll', onPageScroll);

            return () => {
                window.removeEventListener('scroll', onPageScroll);
            };
        });

        const html = elements.map(getLink(chosenElement));

        return (
            <div id="sidebar" className="sidebar d-flex align-items-center">
                <ul className="sidebar-items">
                    {html}
                </ul>
            </div>
        )
    }
;

