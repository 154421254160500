import React from 'react'
import "../styles/Description.css"

const items = [
    "Вычисление геометрических параметров позвоночно-тазового комплекса",
    "Виртуальное планирование операций с подробным отчетом о тактике лечения",
    "Биомеханическая поддержка решения хирурга при выборе лечения",
    "Оценка «выживаемости» и срока жизни имплантатов",
    "База данных с имплантатами, применяемых на территории РФ",
    "Автоматизированное распознавание компьютерных томограмм",
    "Расчет механических свойств костной ткани по компьютерным томограммам",
    "Сбор и хранение всех данных о пациенте и планировании от поступления до реабилитации",
    "Кроссплатформенная система для настольных компьютеров и мобильных устройств",
    "Безопасное хранение данных на серверах на территории РФ",
    "Подробная инструкция пользователя на русском языке"
];

export const Description = () => {
    return (
        <div id="description" className="pages-center pages-links app-text">
            <div>
                <h1 id="description-title" className="page-title">ОПИСАНИЕ СИСТЕМЫ</h1>
                <div id="description-body" className="row">
                    <div id="about-us" className="about-us description-text col-lg-6 col-md-6 col-sm-12">
                        <p>«Система поддержки принятия решений в реконструктивной хирургии позвоночно-тазового
                            комплекса»
                            – единственная в мире Система предоперационного планирования с биомеханической
                            поддержкой.</p>
                        <p>Система реализует формулу цифрового решения = планирование + моделирование + прогноз.</p>
                        <h4>РАЗРАБОТЧИКИ</h4>
                        <p>Лаборатория «Системы поддержки принятия врачебных решений» <br/>
                            <a className="url-link" target="_blank" rel="noopener noreferrer"
                               href="https://www.sgu.ru/"> ФГБОУ ВО «СГУ имени Н.Г. Чернышевского» </a>
                        </p>
                        <p>Отдел инновационных технологий управления в лечении и реабилитации НИИТОН<br/>
                            <a className="url-link" target="_blank" rel="noopener noreferrer"
                               href="https://www.sarniito.com/">ФГБОУ ВО Саратовский ГМУ имени В.И. Разумовского
                                Минздрава России</a>
                        </p>
                        <p>
                            Лаборатория создана совместно
                            <a className="url-link" target="_blank" rel="noopener noreferrer"
                               href="https://fpi.gov.ru/"> Фондом перспективных исследований
                            </a> и <br/>

                            <a className="url-link" target="_blank" rel="noopener noreferrer"
                               href="https://www.sgu.ru/"> ФГБОУ ВО «СГУ имени Н.Г. Чернышевского»
                            </a>
                        </p>

                        <p> Целью лаборатории является разработка систем поддержки принятия врачебных решений в
                            хирургии
                            травматолого-ортопедического профиля.</p>
                    </div>
                    <div id="project-description"
                         className="project-description description-text col-lg-6 col-md-6 col-sm-12">
                        <h3>Преимущества:</h3>
                        <p>
                            {
                                items.map((item, index) => (
                                    <li className="module-info-item" key={index}>{item}</li>
                                ))
                            }
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
};